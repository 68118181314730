import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import(/* webpackChunkName: "solution" */ '../views/Solution.vue')
  },
  {
    path: '/customercase',
    name: 'CustomerCase',
    component: () => import(/* webpackChunkName: "customercase" */ '../views/CustomerCase.vue')
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "aboutus" */ '../views/AboutUs.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
