<template>
	<div id="app">
		<!-- <transition name="fade">
      <header v-if="isShow" class="header">
        <v-header></v-header>
      </header>
    </transition> -->
		<main class="main">
			<v-banner :src="bannerUrl"></v-banner>
			<v-tabs :routerName="routerName"></v-tabs>
			<router-view />
			<div class="footer">赋能新经济 让健康更时尚</div>
			<div class="footer_b">
				<div>
					{{ company_name }}
				</div>
				{{ company_num }}
			</div>
		</main>
	</div>
</template>
<script>
// import Header from '@/components/Header.vue'
import Banner from '@/components/Banner'
import Tabs from '@/components/Tabs'
export default {
  components: {
    // 'v-header': Header,
    'v-banner': Banner,
    'v-tabs': Tabs
  },
  data () {
    return {
      isShow: false,
      bannerUrl: require('@/assets/images/home-banner01.png'),
      routerName: 'Home',
      timer: null,
      company_name: '',
      company_num: ''
    }
  },
  created () {
    var local = window.location.href

    if (local.indexOf('gookang') > -1) {
      this.company_name = '国康中健互联网医院（天津）有限公司'
      if (local.indexOf('ltd') > -1) {
        this.company_num = '津ICP备2021005926号-1'
      }

      if (local.indexOf('com') > -1) {
        this.company_num = '津ICP备2021005926号-2'
      }
    }

    // 成都公司 -> 判断备案号
    if (local.indexOf('kysheng') > -1) {
      this.company_name = '国康中建互联网医院（成都）有限公司'
      if (local.indexOf('net') > -1) {
        this.company_num = '蜀ICP备2021019173号-2'
      }

      if (local.indexOf('cn') > -1) {
        this.company_num = '蜀ICP备2021019173号-1'
      }

      if (local.indexOf('com') > -1) {
        this.company_num = '蜀ICP备2021019173号-3'
      }
    }

    // 北京公司 -> 判断备案号
    if (local.indexOf('guokangzhongjian') > -1) {
      this.company_name = '国康中健（北京）健康科技有限公司'
      if (local.indexOf('com') > -1) {
        this.company_num = '京ICP备2021024524号-1'
      }
    }
  },
  watch: {
    $route: {
      handler (newVal) {
        if (!newVal.name) return
        this.routerName = newVal.name
        if (this.routerName === 'Home') {
          this.isShow = true
        } else {
          this.isShow = false
        }
        const routers = [
          {
            routerName: 'Home',
            bannerUrl: require('@/assets/images/home-banner01.png')
          },
          {
            routerName: 'Solution',
            bannerUrl: require('@/assets/images/solution01.png')
          },
          {
            routerName: 'CustomerCase',
            bannerUrl: require('@/assets/images/customercase01.png')
          },
          {
            routerName: 'AboutUs',
            bannerUrl: require('@/assets/images/aboutus01.png')
          }
        ]
        this.bannerUrl = routers.find(
          (item) => newVal.name === item.routerName
        ).bannerUrl
      },
      immediate: true
    },
    routerName: {
      handler () {
        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.isShow = false
        }, 5000)
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
    height: 60px;
  }
  .main {
    width: 100%;
    flex: 1;
    overflow: auto;
    .footer {
      margin: 10px 0 10px;
      font-size: 10px;
      font-weight: 400;
      color: #9d9e9f;
      text-align: center;
    }
    .footer_b {
      margin-bottom: 20px;
      font-size: 10px;
      font-weight: 400;
      color: #9d9e9f;
      text-align: center;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
