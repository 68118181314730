import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible'
import '@/assets/css/base.css'
// import { wxConfig } from '@/util/wxConfig.js'
Vue.config.productionTip = false

// Vue.config.ignoredElements = [
//   'wx-open-launch-app'
// ]

// TODO
// wxConfig({
//   appId: '',
//   timestamp: '',
//   nonceStr: '',
//   signature: ''
// })
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
