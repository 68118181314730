<template>
  <div class="warp">
    <img :src="src" alt="" />
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    src: {
      type: String,
      default: require('@/assets/images/home-banner01.png')
    }
  }
}
</script>

<style lang="less" scoped>
.warp {
  margin: 0 auto;
  width: 375px;
  height: 224px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
