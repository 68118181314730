<template>
  <div class="warp">
    <div class="title">“专业服务，前所未有的互联网医院建设体验”</div>
    <div class="professional-services">
      <div class="image">
        <img src="@/assets/images/home-banner02.png" alt="" />
      </div>
      <div v-for="(item, index) in list" :key="index" class="text-info">
        <div class="text-name">{{ item.textName }}</div>
        <div class="text-introduces">{{ item.textIntroduces }}</div>
      </div>
    </div>
    <div class="title">“全新体系 走进互联网医院”</div>
    <div class="new-system">
      <div class="text-introduces">
        以实体医院为主体实现在线问诊。让复诊患者疾病百科随时看，健康问题及时提，方便快捷，足不出户就可以在线求医。
      </div>
      <div class="image">
        <img src="@/assets/images/home-banner03.png" alt="" />
      </div>
    </div>
    <div class="title">“更全面的服务体验 快医生”</div>
    <div class="service-experience">
      <div class="text-introduces">
        互联网+快医生，提升医生的服务口碑；全程院外跟访，实时掌握患者动态。助力患者康复，提高患者康复治疗；增进医患联系，提高患者治疗依从性。
      </div>
      <div class="image">
        <img src="@/assets/images/home-banner04.png" alt="" />
      </div>
    </div>
    <div class="nav_btn">
        <a class="btn_down" href="https://apps.apple.com/cn/app/%E5%BF%AB%E5%8C%BB%E7%94%9F-%E5%8C%BB%E7%94%9F%E7%AB%AF/id1594341815" target="_blank"><img src="@/assets/images/ios.png" alt=""></a>
        <a class="btn_down" href="https://doctor.kysheng.com/doctorapp.apk" download="快医生"><img src="@/assets/images/android.png" alt=""></a>
    </div>
    <!-- <wx-open-launch-app
      @error="handleErrorFn"
      @launch="handleLaunchFn"
      appid="微信开放平台绑定的移动应用的AppId"
      extinfo="传递给App的参数"
    >
      <script type="text/wxtag-template">
        <style>
          .app-btn {
            position: fixed;
            bottom: 15px;
            left: 50%;
            width: 88px;
            height: 31px;
            background: #045df0;
            border-radius: 16px;
            line-height: 31px;
            text-align: center;
            margin-left: -44px;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
        </style>
        <div class="app-btn"><span>打开App</span></div>
      </script>
    </wx-open-launch-app> -->
  </div>
</template>

<script>
export default {
  name: 'Home',
  computed: {
    list () {
      return [
        {
          textName: '视频问诊',
          textIntroduces:
            '视频问诊通过患者小程序、医生小程序、医生工作站的使用，全渠道服务患者，满足患者足不出户看医生的需求。并且视频问诊能够提供开具电子病历、处方、检查/检验以及治疗方案等服务'
        },
        {
          textName: '图文咨询',
          textIntroduces:
            '为患者和医生搭建起沟通的平台，方便患者解决健康咨询、报告单解读、用药咨询等问题。图文咨询功能可以为患者解答一些常见的就医问题，有效引导与分流患者，帮助患者看病就医少走弯路，更加合理地分配医疗资源，提高诊疗效率'
        },
        {
          textName: '云随访',
          textIntroduces:
            '通过在线咨询的方式多方位支持医生与患者之间的沟通，通过专业的患者随访工具可有效管理患者和提高患者复诊率'
        },
        {
          textName: '云客服',
          textIntroduces:
            '通过小程序在线客服的方式多方位支持客服与患者之间的沟通，可有效管理患者和提高患者复诊率，为患者带来更好的诊疗体验'
        },
        {
          textName: '云会诊',
          textIntroduces:
            '通过「互联网医院」远程云会诊服务，为医院搭建起跨屏（PC、手机）跨平台的医患远程会诊体系，医患可通过远程视频进行专业的远程会诊。有效激活医院的专家资源，让患者实现在本地医院就诊、转诊、治疗'
        }
      ]
    }
  },
  methods: {
    // 监听error 函数
    // handleErrorFn (e) {
    //   // 跳转失败
    //   console.log(JSON.stringify(e), '跳转失败')
    //   // download() // 这里跳转App下载页面
    // },
    // 监听launch 函数
    // handleLaunchFn (e) {
    //   // 跳转成功
    //   console.log(JSON.stringify(e), '跳转成功')
    // }
  }
}
</script>

<style lang="less" scoped>
.warp {
  width: 100%;
  padding: 0 25px;
  .title {
    height: 35px;
    font-size: 12px;
    font-weight: 600;
    color: #045df0;
    line-height: 35px;
    text-align: center;
  }
  .professional-services {
    .image {
      width: 243px;
      height: 157px;
      margin: 0 auto 10px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .text-info {
      margin-bottom: 15px;
      .text-name {
        height: 22px;
        font-size: 12px;
        font-weight: 600;
        color: #000000;
        line-height: 22px;
        &::before {
          display: inline-block;
          content: "";
          width: 5px;
          height: 10px;
          background: #045df0;
          border-radius: 3px;
          margin-right: 5px;
        }
      }
      .text-introduces {
        font-size: 10px;
        font-weight: 400;
        color: #9d9e9f;
        line-height: 18px;
        margin-left: 10px;
      }
    }
  }
  .new-system {
    width: 100%;
    .text-introduces {
      font-size: 10px;
      font-weight: 400;
      color: #9d9e9f;
      line-height: 18px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
    .image {
      width: 299px;
      height: 195px;
      margin: 0 auto 10px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .service-experience {
    width: 100%;
    .text-introduces {
      font-size: 10px;
      font-weight: 400;
      color: #9d9e9f;
      line-height: 18px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
    .image {
      width: 181px;
      height: 163px;
      margin: 0 auto 10px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav_btn {
    width: 100%;
    height: 100px;
    //background: #0a60eb;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn_down {
      //background: #9ebff7;
      width: 178px;
      height: 81px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
