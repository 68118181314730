<template>
  <div class="warp">
    <div class="tab-list">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="{ active: item.routerName === routerName }"
        class="tab-item"
        @click="handelClick(item.routerName)"
      >
        {{ item.name }} <i v-if="item.routerName === routerName" class="active-line"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Tabs',
  props: {
    routerName: {
      type: String,
      default: 'Home'
    }
  },
  computed: {
    list () {
      return [
        { name: '国康中健', routerName: 'Home' },
        { name: '解决方案', routerName: 'Solution' },
        { name: '客户案例', routerName: 'CustomerCase' },
        { name: '关于我们', routerName: 'AboutUs' }
      ]
    }
  },
  methods: {
    handelClick (routerName) {
      this.$router.push({
        name: routerName
      })
    }
  }
}
</script>
<style lang="less" scoped>
.warp {
  width: 100%;
  height: 43px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  top: -10px;
  background-color: #fff;
  .tab-list {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .tab-item {
      width: 55px;
      height: 17px;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      color: #9d9e9f;
      line-height: 17px;
      margin: 0 8px;
      &.active {
        color: #313131;
      }
      .active-line {
        display: inline-block;
        width: 55px;
        height: 3px;
        background: linear-gradient(243deg, #0a60eb 0%, #a6ccff 100%);
        border-radius: 2px;
      }
    }
  }
}
</style>
